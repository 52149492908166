.sub-section {
  min-height: 100%;
  width: 100%;
  padding-top: 60px;
}

.sub-section > p {
  margin: 0;
}

