.side-nav-menu {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 250px;
  justify-content: space-around;
}

.nav-menu-list {
  display: inline-block;
  margin: auto;
  text-align: left;
  padding-left: 0px;
  list-style: inside;
}

.nav-tip {
  text-align: center;
  color: #fbfbfb;
  padding: 0px 30px 70px;
}

.nav-tip-msg {
  font-size: 14px !important;
}

@media (max-width:1350px) {
  .side-nav-menu {
    max-width: 200px;
  }
}

@media (max-width:1080px) {
  .side-nav-menu {
    max-width: 175px;
  }
}

@media (max-width:950px)  {
  .side-nav-menu {
    display: none;
  }
}

@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
  .side-nav-menu {
    display: none;
  }
}
