.skillset-wrapper {
  padding-top: 70px;
  padding-left: 100px;
  padding-right: 100px;
}

.tech-icon-line-break {
  position: absolute;
}

.tech-icon-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tech-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech-icon {
  background-color: #fbfbfb;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #192841;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  caret-color: transparent;
}

.tech-tooltip {
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.6875rem;
  word-wrap: break-word;
  font-weight: 500;
  background-color: #fbfbfb;
  color: #192841;
  width: min-content;
  margin: 10px auto 15px;
  caret-color: transparent;
}

.tech-tall-icon {
  max-height: 50%;
  caret-color: transparent;
}

.tech-normal-icon {
  max-width: 50%;
}

@media (max-width:1080px) {
  .skillset-wrapper {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1030px), (max-height: 600px) {
  .skillset-wrapper {
    padding-top: 30px;
  }

  .tech-icon-line-break {
    position: static;
    width: 100%;
  }
}

@media (max-width:950px) {
  .skillset-wrapper {
    padding-left: 70px;
    padding-right: 70px;
  }
  .tech-icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width:650px)  {
  .skillset-wrapper {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width:600px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
  .skillset-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tech-icon {
    width: 60px;
    height: 60px;
  }
}

@media (max-width:530px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 530px)  {
  .skillset-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .tech-icon {
    width: 50px;
    height: 50px;
  }
}

@media (max-width:390px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 390px)  {
  .skillset-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tech-icon {
    width: 40px;
    height: 40px;
  }

  .tech-tooltip {
    padding: 4px 4px;
    font-size: 0.6rem;
  }
}
