.section-content {
    box-sizing: border-box;
    border-width: 15px;
    border-style: solid;
    color: #FBFBFB;
    overflow: hidden;
    height: 640px;
    width: 100%;
    border: double 1em transparent;
    border-radius: 60px;
    background-image: linear-gradient(#192841, #192841), 
                        linear-gradient(to left bottom, #192841 40%, #FBFBFB);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

@media (max-width: 950px) {
    .section-content {
        height: 100vh;
        background-image: none;
        border-radius: 0px;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
}

@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
    .section-content {
        background-image: none;
        height: 100vw;
    }
}