.company-logo {
  background-color: #fbfbfb;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.5;
  caret-color: transparent;
}

.company-logo:hover {
  opacity: 1;
}

.selected-company-logo {
  background-color: #fbfbfb;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  caret-color: transparent;
}

.company-normal-icon {
  width: 70%;
  caret-color: transparent;
}

.company-tall-icon {
  height: 70%;
  caret-color: transparent;
}

@media (max-width:600px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
  .company-logo {
    width: 60px;
    height: 60px;
  }

  .selected-company-logo {
    width: 60px;
    height: 60px;
  }
}

@media (max-width:390px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 390px)  {
  .company-logo {
    width: 50px;
    height: 50px;
  }

  .selected-company-logo {
    width: 50px;
    height: 50px;
  }
}

@media (max-width:270px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 270px)  {
  .company-logo {
    width: 40px;
    height: 40px;
  }

  .selected-company-logo {
    width: 40px;
    height: 40px;
  }
}
