.company-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.company-description {
  margin-top: 45px;
  margin-left: 70px;
  margin-right: 70px;
  height: 300px;
  text-align: center;
  color: #fbfbfb;
}

@media (max-width:1350px) {
  .company-description {
      margin-top: 50px;
      margin-left: 60px;
      margin-right: 60px;
  }
}

@media (max-width: 1080px) {
  .company-description {
      margin-top: 30px;
  }
}

@media (max-width:950px) {
  .company-description {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width:600px) {
  .company-description {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
  }
}

@media (max-width:390px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 390px) {
  .company-description {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }
}
