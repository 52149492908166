.intro-header {
  padding-left: 30px;
}

.intro-header h1 {
  margin: 0;
}

.intro-body {
  padding-left: 100px;
}

.intro-body > p {
  margin: 0;
}

@media (max-width:1080px) {
  .intro-body {
    padding-left: 80px;
  }
}

@media (max-width:950px) {
  .sub-section:nth-child(1) {
    padding-top: 0;
  }

  .intro-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .intro-body {
    padding-left: 70px;
  }
}

@media (max-width:600px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
  .intro-header {
    padding-left: 15px;
  }

  .intro-body {
    padding-left: 60px;
  }
}

@media (max-width:500px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 500px)  {
  .intro-body {
    padding-left: 45px;
  }
}

@media (max-width:390px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 390px)  {
  .intro-body {
    padding-left: 30px;
  }
}

@media (max-height:300px) and (hover: none) and (pointer: coarse) and (orientation: landscape) {
  .sub-section:nth-child(1) {
    padding-top: 0;
  }

  .intro-wrapper {
    height: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
