.menu-item {
    padding-top: 10px;
    color: #FBFBFB;
    text-decoration: underline;
    text-underline-offset: 4px;
    caret-color: transparent;
}

.menu-item:hover {
    color: #FF7477;
    cursor: pointer;
}

.selected-menu-item {
    padding-top: 10px;
    color: #FBFBFB;
    text-decoration: underline;
    text-underline-offset: 4px;
    transform: scale(1.1);
    color: #BB0A21;
    caret-color: transparent;
}

.selected-menu-item:hover {
    cursor: default;
}
