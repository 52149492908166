.site-logo-wrapper {
  margin-top: 23px;
}

.site-logo {
  max-width: 88%;
  caret-color: transparent;
}

@media (max-width:1350px) {
  .site-logo-wrapper {
    min-width: 200px;
  }
}

@media (max-width:1080px) {
  .site-logo-wrapper {
    min-width: 175px;
  }
}

@media (max-width:950px)  {
  .site-logo-wrapper {
    display: none;
  }
}
