.portfolio-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.portfolio-wrapper > div {
    margin-bottom: 30px;
}

.card-box {
    max-width: 200px;
    min-height: 400px;
    height: fit-content;
    position: relative;
    border-radius: 15px !important;
    caret-color: transparent;
}

.sub-section:nth-child(3) {
    padding-top: 100px;
}

@media (max-width:950px) {
    .sub-section:nth-child(3) {
      padding-top: 0;
    }

    .portfolio-wrapper {
        padding-top: 0px;
        flex-wrap: wrap;
    }

    .portfolio-wrapper > div {
        height: 40vh;
    }
}

@media (max-height:860px) and (max-width:950px) {
    .card-box {
        min-height: 250px;
    }

    .portfolio-wrapper > div > div p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

@media (max-height:860px) and (max-width:950px) {
    .card-box {
        min-height: 230px;
    }

    .portfolio-wrapper > div > div p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

@media (max-width: 500px) and (hover: none) and (pointer: coarse) {
    .card-box {
        min-height: auto;
        min-width: auto;
        height: 30vh !important;
        width: 30vw;
    }

    .portfolio-wrapper > div > div p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media (max-height: 500px) and (hover: none) and (pointer: coarse) and (orientation: landscape) {
    .card-box {
        min-height: auto;
        min-width: auto;
        height: 30vw !important;
        width: 30vh;
    }

    .portfolio-wrapper > div > div p {
        -webkit-line-clamp: 2;
    }
}

@media (max-height: 400px) and (hover: none) and (pointer: coarse) and (orientation: landscape){
    .card-box {
        min-height: auto;
        min-width: auto;
        height: 30vw !important;
        width: 35vh;
    }

    .portfolio-wrapper > div > div p {
        -webkit-line-clamp: 2;
    }
}
