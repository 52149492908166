.social-icon-wrapper {
  background-color: #fbfbfb;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
	cursor: pointer;
}

.social-icon {
  width: 60%;
  caret-color: transparent;
}

.social-tooltip {
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.6875rem;
  word-wrap: break-word;
  font-weight: 500;
  background-color: #fbfbfb;
  color: #192841;
  width: min-content;
  margin: 10px auto 15px;
  caret-color: transparent;
}

@media (max-width:600px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
  .social-icon-wrapper {
    width: 60px;
    height: 60px;
  }

  .selected-social-icon-wrapper {
    width: 60px;
    height: 60px;
  }
}

@media (max-width:390px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 390px)  {
  .social-icon-wrapper {
    width: 45px;
    height: 45px;
  }

  .selected-social-icon-wrapper {
    width: 45px;
    height: 45px;
  }

  .social-tooltip {
    padding: 4px 4px;
    font-size: 0.5rem;
  }
}

@media (max-width:300px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 300px)  {
  .social-icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .selected-social-icon-wrapper {
    width: 40px;
    height: 40px;
  }
}
