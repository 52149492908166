.sub-section:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
}

.contact-section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-section-header {
    text-align: center;
    caret-color: transparent;
}

.contact-section-header h1 {
    font-size: clamp(0.8rem, 0.64rem + 1.6vw, 2rem);
}

.social-media-list {
    display: flex;
    justify-content: space-around;
    padding-top: 60px;
}
