html { 
  background-color: #192841;
  scroll-behavior: smooth;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Rotates the screen to keep portrait view in landscape mode */
@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
