.app {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
}

@media (max-width: 950px) {
    .scrollbar {
        top: 44%;
        right: 5px;
        position: absolute;
    }

    .scrollbar > div {
        border-radius: 2px;
        margin-bottom: 5px;
        outline: 1px solid black;;
        height: 8px;
        width: 8px;
        background-color: #fbfbfb;
        margin-left: 7px;
    }

    .scrollbar > div:last-child {
        border: none;
    }
}

@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)  {
    .app {
        height: 100vw;
    }
}
