.project-detail-header {
    margin-top: 0;
    margin-bottom: 2px !important;
}

.project-detail-body {
    margin-top: 0;
    margin-bottom: 8px !important;
}

.project-detail-technologies {
    font-weight: normal !important;
}

.project-highlight-wrapper {
    border-bottom: 1px solid #fbfbfb;
    width: 100%;
    margin: 8px auto 0;
}

.project-description-wrapper p {
    margin: 0;
    margin-left: 24px;
}

.project-wrapper {
    text-align: left;
}

@media (max-width:600px) {
  .project-description-wrapper p {
    margin-left: 16px;
  }
}

@media (max-width:390px), (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 390px) {
  .project-description-wrapper p {
    margin-left: 8px;
  }
}
